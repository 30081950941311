<template>
    <div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Pronto para partir?</h5>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">Selecione "Sair" abaixo se estiver pronto para encerrar sua sessão atual.</div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancelar</button>
                    <a class="btn btn-danger" @click="logout"  href="#">Sair</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LogoutModal',
    methods: {
        logout() {            
            let modal = document.getElementById('logoutModal');
            document.body.classList.remove('modal-open');
            modal.classList.remove('show');
            modal.style.display = 'none';
            const modalBackdrop = document.querySelector('.modal-backdrop');
            if (modalBackdrop) {
                modalBackdrop.remove();
            }
            this.$store.dispatch('logout');
            this.$router.push('/');
        }
    }
};
</script>