<template>
    <div id="wrapper">
        <AppSidebar />    
        <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
            <AppNavbar />
            <div class="container-fluid" style="text-align: justify;">
              <router-view />
            </div>  
        </div>
        <AppFooter />
        </div>
    </div>
    <ScrollToTop />
    <LogoutModal />
</template>

<script>
  import AppNavbar from '@/components/AppNavbar.vue';
  import AppSidebar from '@/components/AppSidebar.vue';
  import AppFooter from '@/components/AppFooter.vue';
  import LogoutModal from '@/components/LogoutModal.vue';
  import ScrollToTop from '@/components/ScrollToTop.vue';
  export default {
      components: {
        AppNavbar,
        AppSidebar,
        AppFooter,
        LogoutModal,
        ScrollToTop
      },
      created() {
        this.updateBodyClass(this.$route);
      },
      methods: {
        updateBodyClass(route) {
          document.body.className = '';
    
          if (route.meta && route.meta.bodyClass) {
            document.body.classList.add(route.meta.bodyClass);
          }
        }
      }
    };
</script>

<style>
@import '../assets/styles/main.scss';
</style>
  

  