export default store => {
    if (localStorage.getItem('store')) {
      store.replaceState(
        Object.assign(
          {},
          store.state,
          JSON.parse(localStorage.getItem('store'))
        )
      );
    }
  
    store.subscribe((mutation, state) => {
      localStorage.setItem('store', JSON.stringify(state));
    });
  };
  