<template>
  <DashboardComponent/>        
</template>

<script>
import DashboardComponent from '@/components/DashboardComponent.vue';

export default {
  components: {
    DashboardComponent,
  },
  created() {
    this.updateBodyClass(this.$route);
  },
  methods: {
    updateBodyClass(route) {
      document.body.className = '';

      if (route.meta && route.meta.bodyClass) {
        document.body.classList.add(route.meta.bodyClass);
      }
    }
  }
};
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
}

.main-content {
  display: flex;
  flex-direction: row;
}

.content {
  flex: 1;
  padding: 20px;
}
</style>
