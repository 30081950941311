<template>
    <div>
      <CadastroServico />
    </div>
  </template>
  
  <script>
  import CadastroServico from '../components/CadastroServico.vue';
  
  export default {
    components: {
      CadastroServico,
    },
  };
  </script>
  
  <style>
  /* Adicione qualquer estilo adicional que você precise aqui */
  </style>
  