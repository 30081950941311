import axios from 'axios';

const app = axios.create({
  baseURL: 'https://apimonitor.intellidevs.com.br',
  headers: {
    'Content-Type': 'application/json',
  },
});

app.interceptors.request.use((config) => {
  const store = localStorage.getItem('store');
  if (store) {
    const parsedStore = JSON.parse(store);
    if (parsedStore.token) {
      config.headers.Authorization = `Bearer ${parsedStore.token}`;
    }
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default app;
