<template>
     <h1 class="h3 mb-2 text-gray-800">{{nome}}</h1>
    <div class="card shadow mb-4">
        <div class="card-body">
          <table id="example" class="display" style="width:100%">
            <thead>
              <tr> <th v-for="(coluna,index) in filteredColunas" :key="index" :class="{ 'text-center': coluna === 'Ativo' }" >{{coluna}}</th>
               
                <th></th>
              </tr>
            </thead>
            <tbody>
            <tr v-for="(dado, index) in dados" :key="index">
              <td v-for="(coluna,idx) in filteredColunas" :key="idx">
                <span v-if="typeof dado[coluna] === 'boolean'" class="dot-container">
                  <span v-if="dado[coluna]" class="dot green-dot"></span>
                  <span v-else class="dot red-dot"></span>
                </span>
                <span v-else>{{ dado[coluna] }}</span>
              </td>
              <td>
                <div class="action-buttons">
                  <a data-toggle="modal" @click="openModal(dado)" data-target="#dadotModal" class="btn btn-success btn-sm"><i class="fas fa-search"></i></a>
                  <router-link :to="{ name: nomeRota, params: { id: dado.id } }" class="btn btn-warning btn-sm"><i class="fas fa-edit"></i></router-link>
                  <button data-target="#deleteModal" data-toggle="modal" @click="openModal(dado)" class="btn btn-danger btn-sm"><i class="fas fa-trash"></i></button>
                </div>
              </td>
            </tr>
          </tbody>
          </table>           
        </div>
    </div>
    
    <div class="modal fade" id="dadotModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div v-if="selectedDado" class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{nomeModal}}</h5>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                  <p v-for="(coluna,idx) in filteredColunas" :key="idx"><strong>{{coluna}}:</strong> {{ selectedDado[coluna] }}</p>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-danger" type="button" data-dismiss="modal">OK</button>
                </div>
            </div>
        </div>
    </div> 
    <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel2" aria-hidden="true">
      <div v-if="selectedDado" class="modal-dialog" role="document">
        <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel2">Deseja realmente apagar os dados abaixo?</h5>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                  <p v-for="(coluna,idx) in filteredColunas" :key="idx"><strong>{{coluna}}:</strong> {{ selectedDado[coluna] }}</p>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancelar</button>
                    <button class="btn btn-danger" type="button" @click="deleteDados()">Apagar</button>
                </div>
            </div>
      </div>
    </div>
</template>

<script>
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import app from '@/api/';

export default {
  name: 'AppLista',
  props: {
    nome: {
      type: String,
      required: true,
      default: 'Lista'
    },
    nomeRota: {
      type: String,
      required: true,
      default: 'Rota'
    },
    nomeModal: {
      type: String,
      required: true,
      default: 'Dado'
    },
    colunas: {
      type: Array,
      required: true,
      default: () => []
    },
    dados: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      selectedDado: null
    };
  },
  computed: {
    filteredColunas() {
      return this.colunas.filter(coluna => coluna !== 'Senha');
    }
  },
  mounted() {
    setTimeout(() => {
      $('#example').DataTable({
        pageLength: 25,
        "lengthMenu": [[10, 25, 50, 100, -1], [10, 25, 50, 100, "Tudo"]], // permite listar todos os itens
        "language": {
          "url": "https://cdn.datatables.net/plug-ins/1.11.3/i18n/pt_br.json"
        }
      });
    }, 600);
  },
  methods: {
    openModal(dado) {
      this.selectedDado = dado;
    },
    deleteDados() {
      let url = this.$props.nomeRota === 'Servico' ? '/projects' : '/users';
      app.delete(`${url}/${this.selectedDado.id}`)
        .then(() => {
          this.$router.go();
        });
    }
  }
}
</script>

<style scoped>
.action-buttons {
  display: flex;
  gap: 5px; /* Espaço entre os botões */
}

.dot-container {
  display: flex;
  align-items: center;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.green-dot {
  background-color: green;
}

.red-dot {
  background-color: red;
}
.text-center {
  text-align: center !important;
}
</style>