<template>
  <AppLista nome="Serviços" nomeModal="Dados do Serviço" nomeRota="Servico" :colunas="columns" :dados="data"/>  
</template>

<script>
import AppLista from '@/components/AppLista.vue';
import app from '@/api/';

export default {
  components: {
    AppLista
  },
  data() {
    return {
      data: [],
      columns: []
    };
  },  
  created() {
    this.fetchData();
  },
  methods:{
    async fetchData(){
        const response = await  app.get('/projects');
        this.columns = Object.keys(response.data[0]).map(chave => String(chave));  
        this.data = response.data;
      }
  } 
};
</script>
