<template>
  <div class="container-wrapper">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-10 col-lg-12 col-md-9">
          <div class="card o-hidden border-0 shadow-lg my-5">
            <div class="card-body p-0">
              <div class="row">
                <div class="col-lg-6 d-none d-lg-block bg-login-image ">
                  <img src="/logo-intelliway.svg" alt="nature water" class="img-fluid" />
                </div>
                <div class="col-lg-6">
                  <div class="p-5">
                    <div class="text-center">
                      <h1 class="h4 text-gray-900 mb-4">Intelli Monitor</h1>
                    </div>
                    <form @submit.prevent="login" class="user">
                      <div class="form-group">
                        <input v-model="username" id="username" class="form-control form-control-user" placeholder="Usuário" />
                      </div>
                      <div class="form-group mt-3">
                        <input v-model="password" id="password" type="password" class="form-control form-control-user" placeholder="Senha" />
                      </div>
                      <button type="submit" class="btn btn-primary-intelliway btn-user btn-block">Entrar</button>
                    </form>
                    <hr>
                    <div class="text-center">
                        <a class="small" href="forgot-password.html">Esqueceu sua senha?</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>    
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: ''
    };
  },
  created() {
    this.updateBodyClass(this.$route);
  },
  methods: {
    login() {
      this.$store.dispatch('login', {
        username: this.username,
        password: this.password
      }).then(() => {
        if (this.$store.state.isAuthenticated) {
          this.$router.push('/painel');
        }
      });
    },
    updateBodyClass(route) {
      document.body.className = '';

      if (route.meta && route.meta.bodyClass) {
        document.body.classList.add(route.meta.bodyClass);
      }
    }
  }
};
</script>

<style scoped>  
  .container-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Altura total da tela */
  }
  .bg-login-image {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 389.55px; /* Certifique-se de que a altura está definida */
    padding: 50px
  }
  a{
    color: rgb(172, 34, 42) !important;
  }

  a:hover{
      color: #CA0A27 !important;
  }
</style>
