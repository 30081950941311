<template>
    <div class="container mt-5">
      <h2>{{ modoEdicao ? 'Editar Serviço' : 'Cadastro de Serviço' }}</h2>
      <form @submit.prevent="submitForm" class="needs-validation" novalidate ref="form">
        <div class="mb-3">
          <label for="service" class="form-label">Service</label>
          <input
            type="text"
            class="form-control"
            id="service"
            v-model="form.service"
            required
          />
          <div class="invalid-feedback">Por favor, informe o serviço.</div>
        </div>
  
        <div class="mb-3">
          <label for="method" class="form-label">Method</label>
          <input
            type="text"
            class="form-control"
            id="method"
            v-model="form.method"
            required
          />
          <div class="invalid-feedback">Por favor, informe o método.</div>
        </div>
  
        <div class="mb-3">
          <label for="url" class="form-label">URL</label>
          <input
            type="text"
            class="form-control"
            id="url"
            v-model="form.url"
            required
          />
          <div class="invalid-feedback">Por favor, informe a URL.</div>
        </div>
  
        <button type="submit" class="btn btn-danger">{{ modoEdicao ? 'Salvar' : 'Cadastrar' }}</button>
      </form>
  
      <div v-if="submitted" class="alert alert-success mt-3">
        {{ modoEdicao ? 'Serviço atualizado com sucesso!' : 'Serviço cadastrado com sucesso!' }}
        <ul>
          <li>Service: {{ form.service }}</li>
          <li>Method: {{ form.method }}</li>
          <li>URL: {{ form.url }}</li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  import app from '@/api/'
  export default {
    data() {
      return {
        form: {
          service: '',
          method: '',
          url: '',
        },
        submitted: false,
        modoEdicao: false, // Flag para indicar se estamos editando um serviço
      };
    },
    mounted() {
      // Verifica se há um ID na URL ao montar o componente
      const id = this.$route.params.id;
      if (id) {
        // Simula uma requisição para buscar os dados do serviço pelo ID (substitua com sua lógica real)
       this.carregarDadosServico(id);
      }
    },
    methods: {
      async submitForm() {
       
        this.$refs.form.classList.add('was-validated');
        if (!this.$refs.form.checkValidity()) {
          return;
        }

        let response = null;
        if (this.modoEdicao) {
          response = await app.put(`/projects/${this.$route.params.id}`, this.form);
        } else {
          response = await app.post('/projects', this.form);  
        }

        if(response.status === 201 || response.status === 200){
          this.submitted = true;
          setTimeout(() => {
            this.$router.push('/painel/servicos');
          }, 2000);
        }
        // Aqui você pode enviar os dados do formulário para o backend
      },
      async carregarDadosServico(id) {
        const response = await  app.get(`/projects/${id}`);
        let data = response.data;
        this.form.service = data.service;
        this.form.method = data.method;
        this.form.url = data.url;
        this.modoEdicao = true; // Indica que estamos em modo de edição
      },
    },
  };
  </script>
  
  <style>
  /* Adicione qualquer estilo adicional que você precise aqui */
  </style>
  