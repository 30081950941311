<template>
    <div class="container mt-5">
      <h2>{{ modoEdicao ? 'Editar Usuário' : 'Cadastro de Usuário' }}</h2>
      <form @submit.prevent="submitForm" class="needs-validation" novalidate ref="form">
        <div class="mb-3">
          <label for="user" class="form-label">Usuário</label>
          <input
            type="text"
            class="form-control"
            id="user"
            v-model="form.username"
            required
          />
          <div class="invalid-feedback">Por favor, informe o usuário.</div>
        </div>
  
        <div class="mb-3">
          <label for="password" class="form-label">Senha</label>
          <input
            type="password"
            class="form-control"
            id="password"
            v-model="form.password"
            required
          />
          <div class="invalid-feedback">Por favor, informe a senha.</div>
        </div>
  
        <button type="submit" class="btn btn-danger">{{ modoEdicao ? 'Salvar' : 'Cadastrar' }}</button>
      </form>
  
      <div v-if="submitted" class="alert alert-success mt-3">
        {{ modoEdicao ? 'Usuário atualizado com sucesso!' : 'Usuário cadastrado com sucesso!' }}
        <ul>
          <li>Usuário: {{ form.user }}</li>
          <li>Senha: {{ form.password }}</li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  import app from '@/api/'
  export default {
    data() {
      return {
        form: {
          username: '',
          password: '',
          confirmpassword:''
        },
        submitted: false,
        modoEdicao: false, // Flag para indicar se estamos editando um usuário
      };
    },
    mounted() {
      // Verifica se há um ID na URL ao montar o componente
      const id = this.$route.params.id;
      if (id) {
        // Simula uma requisição para buscar os dados do usuário pelo ID (substitua com sua lógica real)
        this.carregarDadosUsuario(id);
      }
    },
    methods: {
      async submitForm() {
            this.$refs.form.classList.add('was-validated');
            if (!this.$refs.form.checkValidity()) {
              return;
            }   
            this.form.confirmpassword = this.form.password;
            const response = this.modoEdicao ? await app.put(`/users/${this.$route.params.id}`, this.form) : await app.post('/users', this.form);
            if(response.status == 200 || response.status == 201){              
              this.submitted = true;
              setTimeout(() => {
                this.$router.push('/painel/usuarios');
              }, 2000);
            }

      },
      async carregarDadosUsuario(id) {
        const response = await  app.get(`/users/${id}`);
        let data = response.data;
        this.form.username = data.Nome
        this.form.password = data.Senha
        this.form.confirmpassword = data.Senha
        this.modoEdicao = true; // Indica que estamos em modo de edição
      },
    },
  };
  </script>
  
  <style>
  /* Adicione qualquer estilo adicional que você precise aqui */
  </style>
  