import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store';
import LayoutMain from '@/layouts/LayoutMain.vue'
import LayoutLogin from '@/layouts/LayoutLogin.vue'
// import LayoutError from '@/layouts/LayoutError.vue'
import LoginView from '@/views/LoginView.vue'
import HomeView from '@/views/HomeView.vue'
import ServicosView from '@/views/ServicosView.vue'
import ServicoView from '@/views/ServicoView.vue'
import UsuariosView from '@/views/UsuariosView.vue'
import UsuarioView from '@/views/UsuarioView.vue'

const routes = [
  {
    path: '/',
    component: LayoutLogin,
    meta:{
      bodyClass: 'bg-gradient-primary'
    },
    children: [
      {
        path: '',
        name: 'Login',
        component: LoginView
      }
    ]
  },
  {
    path: '/painel',
    component: LayoutMain,
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: HomeView
      },
      {
        path: 'servicos',
        name: 'Servicos',
        children: [
          {
            path: '',
            name: 'Servicos',
            component: ServicosView
          },
          {
            path: ':id',
            name: 'Servico',
            component: ServicoView
          },
          {
            path: 'novo',
            name: 'Novo Servico',
            component: ServicoView
          }
        ]
      },
      {
        path: 'usuarios',
        name: 'Usuarios',
        children: [
          {
            path: '',
            name: 'Usuarios',
            component: UsuariosView
          },
          {
            path: ':id',
            name: 'Usuario',
            component: UsuarioView
          },
          {
            path: 'novo',
            name: 'Novo Usuario',
            component: UsuarioView
          }
        ]
      }
    ],
    meta: { bodyClass: 'page-top', requiresAuth: true }
  },
  // {
  //   path: '*',
  //   component: LayoutError
  // }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = store.state.isAuthenticated;
  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next('/');
  } else {
    next();
  }
});

export default router
