import { createStore } from 'vuex'
import persistState from './plugins/persistState'
import app from '../api'

export default createStore({
  state: {
    isAuthenticated: false,
    token: null,
  },
  getters: {
    isAuthenticated: state => state.isAuthenticated,
  },
  mutations: {
    setAuth(state, payload) {
      state.isAuthenticated = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    }
  },
  actions: {
    login({ commit }, credentials) {
      console.log('Iniciando login com credenciais:', credentials);
      return app.post('/login', credentials).then(response => {
        console.log('Resposta do servidor:', response);
        commit('setToken', response.data.token);
        commit('setAuth', true);
        return response;
      }).catch(error => {
        console.error('Erro na ação de login:', error);
        throw error;
      });
    },
    logout({ commit }) {
      commit('setAuth', false);
      localStorage.removeItem('store');
    }
  },
  modules: {
  },
  plugins: [persistState]
})
