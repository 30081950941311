<template>
    <AppLista nome="Usuários" nomeModal="Dados do Usuário" nomeRota="Usuario" :colunas="columns" :dados="data"/>  
  </template>
  
  <script>
  import AppLista from '@/components/AppLista.vue';
  import app from '@/api/';
  
  export default {
    components: {
      AppLista
    },
    data() {
      return {
        data: [],
        columns: []
      };
    },  
    created() {
      this.fetchData();
    },
    methods:{
      async fetchData(){
        const response = await  app.get('/users');
        this.columns = Object.keys(response.data[0]).map(chave => String(chave));  
        this.data = response.data; 
      }
    } 
  };
  </script>
  